<template>
    <v-container fluid>  
        <TheTitle :text="title" :icon="pageIcon">
            <v-row justify="center">
                <v-col md="8" sm="10" cols="10">
                    <v-text-field
                    placeholder="Procurar"
                    dense
                    solo
                    background-color="#FFFFFF"
                    hide-details
                    v-model="search"            
                    ></v-text-field>
                </v-col>
                <v-col md="4" sm="4" cols="4">
                    <v-btn block color="amber darken-4" dark @click="dialogSave()">
                        <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar
                    </v-btn>
                    <v-btn class="mt-3" block color="success" dark @click="$router.back()">
                        <v-icon small class="mr-2">mdi-arrow-left</v-icon> Voltar
                    </v-btn>                    
                </v-col>
            </v-row>
        </TheTitle>

        <v-card class="pa-5">
            <v-row>
                <v-col md="2" sm="12" cols="12">
                    <b>COD</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.id"
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="4" sm="12" cols="12">
                    <b>Plano de ação</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.name"
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="4" sm="12" cols="12">
                    <b>Responsável</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        :value="getPersonName(objects.idPeople)"
                        class="my-2"
                    ></v-text-field>
                </v-col>
                <v-col md="2" sm="12" cols="12">
                    <b>Prazo</b>
                    <v-text-field
                        dense
                        tile
                        outlined
                        disabled
                        v-model="objects.validate"
                        class="my-2"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="pa-5 mt-5 full-height">
            <v-data-table
                :headers="header"
                :items="step"
                dense
                locale="pt-BR"
                :search="search"
                :items-per-page="15"
            >
                <template v-slot:item.name="{ item }">
                    {{ getPersonName(item.idPerson) }}
                </template> 
                <template v-slot:item.deadline="{ item }">
                    {{ item.deadline | formatDate3 }}
                </template>             
                <template v-slot:[`item.action`]="{ item }">
                    <TheToolTip label="Editar">
                    <v-btn icon small tile @click="allowEdition = true">
                        <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
                    </v-btn>
                    </TheToolTip>
                    <TheToolTip label="Remover">
                        <v-btn icon small tile>
                        <v-icon @click="remove(item.id)">mdi-minus</v-icon>
                        </v-btn>
                    </TheToolTip>
                </template>                
            </v-data-table>
        </v-card>

        <TheDialog v-model="dialog" :title="title" :icon="pageIcon" @close-dialog="loadData" width="800px">
            <template>
                <v-form v-model="valid" ref="form1">
                    <v-row>
                        <v-col>
                            <b> Nome da etapa <sup>*</sup></b>
                            <v-text-field
                                placeholder="Name"
                                v-model="object.title"
                                class="my-2"
                                dense
                                hide-details
                                outlined
                                color="primary"
                                :rules="[requiredRule]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="4" sm="12" cols="12">
                        <b> Tipo de Plano </b>
                        <v-select
                            :items="type"
                            item-text="name"
                            item-value="value"
                            v-model="object.typePlan"
                            class="my-2"
                            dense
                            hide-details
                            outlined
                            color="primary"
                        ></v-select>
                        </v-col>

                        <v-col md="4" sm="12" cols="12">
                            <b> Prazos </b>
                            <v-menu
                                ref="menu1"
                                v-model="menu1"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="object.deadline"
                                        placeholder="Data"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        @input="date = parseDate(object.deadline)"
                                        v-on="on"
                                        hide-details
                                        dense
                                        outlined
                                        class="mt-2"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    :rules="requiredRule"
                                    v-model="date"
                                    no-title
                                    @input="menu1 = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col md="4" sm="12" cols="12">
                            <b> Responsável </b>
                            <v-select
                                :items="people"
                                item-text="name"
                                item-value="id"
                                v-model="object.idPerson"
                                class="my-2"
                                dense
                                hide-details
                                outlined
                                clearable
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <b> Descrição da Ação *</b>
                            <v-textarea
                                outlined
                                rows="3"
                                v-model="object.description"
                                class="my-2"
                                auto-grow
                                :rules="[requiredRule, spaceRule]"
                            ></v-textarea>
                        </v-col>
                    </v-row>                    
                </v-form>
            </template>
            <template v-slot:actions="">
                <v-btn
                    small
                    depressed
                    color="primary"                 
                    @click="resolveForm()"
                >
                    <span v-if="!edit"><v-icon small class="mr-2">mdi-plus</v-icon>Adicionar</span>
                    <span v-if="edit" ><v-icon small class="mr-2">mdi mdi-update</v-icon>Atualizar </span>
                </v-btn>
                <v-spacer></v-spacer>
                <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
            </template>
        </TheDialog>

    </v-container>
</template>


<script>
    import TheTitle from "@/components/TheTitle"
    import TheDialog from "@/components/TheDialog"
    import TheToolTip from "@/components/TheToolTip"
    import { mask } from "vue-the-mask"
    import { baseApiUrl, showError } from "@/global"
    import axios from "axios"
    import moment from 'moment' 
  
    export default {
    
        name: "Etapas",
        directives: { mask },
        components: {
            TheTitle,
            TheDialog,
            TheToolTip,
        },
        
        data: () => ({
            baseRoute: "actionSteps",
            title: "Etapas do Plano de ação",
            pageIcon: "mdi mdi-format-list-checkbox",

            objects: [],
            object: {},
            people:[],
            step:[],

            menu1: false,
            date: null,

            modal: false,
            dialog: false,
            search: null,
            valid: false,
            error: false,
            edit: false,
            loading: false,

            header: [
                { text: "Nome", value: "title"},
                { text: "Responsável", value: "name"},
                { text: "Tipo de Plano", value: "typePlan"},
                { text: "Prazo", value: "deadline"},
                { text: "Ações", value: "action", width: "10%" },
            ],
            type: [
                { name: "Geral", value: "Geral", id: "0" },
                { name: "Específico", value: "Específico", id: "1" },
            ],
        }),

        watch: {
            date() {
                this.object.deadline = this.date
                this.object.deadline = moment(this.object.deadline).format('DD/MM/YYYY')
            },
        },

        computed: {
            getPersonName() {
            return function(idPerson) {
                const person = this.people.find((el) => el.id === idPerson);
                if (person) {
                return person.name;
                } else {
                return "";
                }
            };
            },
        },

        methods: {  

            loadPerson() {
                const url = `${baseApiUrl}/people`
                return axios
                    .get(url)
                    .then((res) => {
                        this.people = res.data
                    })
                    .catch(showError)
            },
            
            async loadData() {
                this.$store.dispatch("setLoadingInfo", true)
                const url = `${baseApiUrl}/plan/search/${this.$route.params.id}/plan_stap`

                const res = await axios.get(url)

                this.$store.dispatch("setLoadingInfo", false)

                this.step = res.data 
            },

            loadItem() {
                const id = this.$route.params.id

                this.$store.dispatch("setLoadingInfo", true)
                const url = `${baseApiUrl}/plan/${id}`

                axios
                    .get(url)
                    .then((res) => {
                        this.$store.dispatch("setLoadingInfo", false)
                        this.objects = res.data
                        this.objects.namePerson = this.getPersonName(this.objects.idPeople)
                        this.objects.validate = moment(this.objects.validate).format('DD/MM/YYYY')                      
                    })
                    .catch(showError)                
            },

            dialogSave() {
                this.object = {}
                this.edit = false
                this.dialog = true
            },

            dialogUpdate(item) {
                this.object = item;
                this.object.deadline = moment(new Date(this.object.deadline)).format('DD/MM/YYYY')          
                this.edit = true
                this.dialog = true         
            },  

            resolveForm(){
                this.$refs.form1.validate()
                if (!this.valid){
                    this.$store.dispatch("setErrorInfo", {
                        open: true,
                        text: "Os campos não foram preenchidos corretamente, você pode verificar?",
                        button: "Ok!",
                    })
                    return
                }
                if(this.edit){
                    this.update()
                } else {
                    this.save()
                }
            },
            
            save(){

                this.error = false
                const url = `${baseApiUrl}/actionSteps`
                const objects = this.object
                objects.idAction = this.objects.id
                objects.idCompany = this.objects.idCompany

                axios
                    .post(url, objects)
                    .then(() => this.loadData())
                    .catch(showError)
                    .finally(() => {
                        this.dialog = false
                    }) 
            },

            update() {
                this.error = false
                const id = this.object.id
                const url = `${baseApiUrl}/actionSteps/${id}`

                const objects = this.object  

                delete objects.id
                delete objects.createdAt
                delete objects.updatedAt
                delete objects.deletedAt

                this.$store.dispatch("setLoadingInfo", true)
                axios
                    .put(url, objects)
                    .then(() => this.loadData())
                    .catch(showError)
                    .finally(() => {
                        this.dialog = false
                    })
            },
                            
            remove(id) {
                if (confirm("Remove?")) {
                    const url = `${baseApiUrl}/actionSteps/${id}`
                    axios
                    .delete(url)
                    .then(() => this.loadData())
                    .catch(showError)
                }
            },

            parseDate(date) {
                if (!date) return null
                const [month, day, year] = date.substring(0, 11).split("/")
                return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
            },
        },
        mounted() {
            this.loadPerson()
            this.loadData()
            this.loadItem()
        }
    }
</script>

<style>
.full-height {
  height: 50vh;
}
</style>